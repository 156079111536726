import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { getThemeVar } from 'src/styles/mixins'
import Heading2Line from 'src/components/Heading2Line'
import { Grid, Box } from '@material-ui/core/'
import Section from 'src/components/Section'
import SectionPattern from 'src/components/SectionPattern'
import ImageWithBackground from 'src/components/ImageWithBackground'
import Wysiwyg from 'src/components/Wysiwyg'
import LinkButton from 'src/components/LinkButton'
import { getRelativeUrl } from 'src/utils/urlUtils'

export const DevelopmentFieldsTemplate = ({ acf }) => {
  const intro = acf.developmentFieldsOverviewIntro
  const developmentFields = acf.developmentFieldsOverviewDevelopmentFields

  const fieldsMapped = developmentFields.map(
    ({ image, preHeading, heading, textWysiwyg, link }, i) => (
      <Section
        key={uuidv4()}
        background={i % 2 ? getThemeVar('color.lightblue') : undefined}
        hasPaddingLarge
        paddingValuesLarge={
          i === developmentFields.length - 1
            ? { xs: '48px 0  96px 0', lg: '100px 0 148px 0' }
            : undefined
        }
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={4}
        >
          <Box clone order={{ xs: 1, md: i % 2 ? 2 : 1 }}>
            <Grid item xs={12} md={6}>
              <ImageWithBackground
                imgFluid={image.localFile.childImageSharp.fluid}
                alt={image.altText}
                position={i % 2 ? 'right' : 'left'}
              />
            </Grid>
          </Box>
          <Box clone order={{ xs: 2, md: i % 2 ? 1 : 2 }}>
            <Grid item xs={12} md={6}>
              <Heading2Line preHeading={preHeading} heading={heading} />
              <Wysiwyg>{textWysiwyg}</Wysiwyg>
              <Box display="flex" justifyContent="start" mt={6}>
                <LinkButton to={getRelativeUrl(link.url)}>
                  {link.title}
                </LinkButton>
              </Box>
            </Grid>
          </Box>
        </Grid>
        {i < developmentFields.length - 1 ? (
          <SectionPattern positionX={i % 2 ? 'right' : 'left'} />
        ) : (
          undefined
        )}
      </Section>
    )
  )

  return (
    <>
      <Section background={getThemeVar('color.lightblue')} hasPaddingLarge>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={4}
        >
          <Grid item xs={12} md={10}>
            <Heading2Line
              preHeading={intro.preHeading}
              heading={intro.heading}
            />
            <Wysiwyg>{intro.textWysiwyg}</Wysiwyg>
          </Grid>
        </Grid>
        <SectionPattern positionX="right" />
      </Section>
      {fieldsMapped}
    </>
  )
}

DevelopmentFieldsTemplate.propTypes = {
  acf: PropTypes.shape({
    developmentFieldsOverviewIntro: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
    }),

    developmentFieldsOverviewDevelopmentFields: PropTypes.arrayOf(
      PropTypes.shape({
        preHeading: PropTypes.string,
        heading: PropTypes.string,
        textWysiwyg: PropTypes.string,
        image: PropTypes.object, // eslint-disable-line
        link: PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
}

export default DevelopmentFieldsTemplate
